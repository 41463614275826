
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$dot-size: 8px;
$dot-color: $theme-dark-lt;
$dot-active-color: $theme-alert;

.link {
  color: $theme-dark-dk;
  font-weight: 600;
  @include hover-focus {
    color: $theme-dark;
  }
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.dot-wrapper {
  position: relative;
  margin-right: $gl-spacer-xxs;
  margin-bottom: 1px;
}

.dot {
  background-color: $dot-color;
  @include size($dot-size);
  border-radius: 50%;
  top: 0;
  left: 0;

  &.hide-pulse {
    display: none;
  }
}

.dot-wrapper--active .dot {
  background-color: $dot-active-color;
  &:after,
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    @include size($dot-size);
    background-color: $dot-active-color;
    border-radius: 50%;

    animation:  size ease-out 1.4s,
                opacity linear 1.4s;
    animation-iteration-count: infinite;
  }

  &:after {
    animation-delay: .35s;
  }
}

@keyframes size {
  0% { transform: scale(1); }
  50% { transform: scale(1.75); }
  100% { transform: scale(1.75); }
}
@keyframes opacity {
  0% { opacity: .5;}
  25% { opacity: .5;}
  60% { opacity: 0;}
  100% { opacity: 0;}
}
