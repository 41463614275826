
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$dd-list-min-width: 240px;

.container {
  display: inline-block;
  position: relative;
}


.toggle {
  display: inline-block;
}

////////////////

.list {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  background: $theme-light-lt;
  border-radius: $gl-radius-base;
  box-shadow: $gl-box-shadow-m;
  overflow: auto;
  width: 100%;
}

// Simple Dropdown list

.menu {
  position: absolute;
  z-index: $gl-z-index-pre-max;
  top: 100%;
  left: 0;
  min-width: rem($dd-list-min-width);
  font-weight: 500;

  &.top {
    top: 0;
    transform: translate(0, -100%);
  }

  &.top-right {
    left: 100%;
    top: 0;
    transform: translate(-100%, -100%);
  }

  &.top-center {
    left: 50%;
    top: 0;
    transform: translate(-50%, -100%);
  }

  &.right {
    left: 100%;
    transform: translate(-100%, 0);
  }

  &.center {
    left: 50%;
    transform: translate(-50%, 0);
  }
}



.item {
  @include button-reset();
  box-sizing: border-box;
  line-height: inherit;
  display: block;
  width: 100%;
  text-align: inherit;
  font-weight: inherit;
  margin: 0;
  padding: $gl-spacer-xxs $gl-spacer-m;
  color: $theme-dark-dk;
  cursor: pointer;

  &:not(.disabled):not(.no-hover) {
    &:hover {
      @extend .highlighted;
    }
  }

}


.selected,
.highlighted {
  color: $theme-dark-dk;
  text-decoration: none;
  background: $theme-light;
}

.bold {
  div {
    font-weight: 700;
  }
}

.disabled {
  background: $theme-light-dk;
  color: $theme-dark-ltr;
}

.item,
.group {
  &:first-child {
    margin-top: $gl-spacer-s;
  }

  &:last-child {
    margin-bottom: $gl-spacer-s;
  }
}


.group {
  position: relative;

  &__label {
    padding: $gl-spacer-s 0 $gl-spacer-3xs;
    padding-left: $gl-spacer-m + $gl-asset-size-3xs +  $gl-spacer-xxs;
    line-height: 1;
    font-weight: 700;
    color: $theme-dark;
    text-transform: uppercase;
    font-size: $gl-font-size-3xs;
  }

  @at-root {
    * + & {
      margin-top: $gl-spacer-xs;
      border-top: 1px solid $theme-light-dk;

    }
  }

  .item {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
