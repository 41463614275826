
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.link {
  display: block;
  padding: $gl-spacer-xxs $gl-spacer-m;
  &:last-child {
    margin-bottom: $gl-spacer-s;
  }

  &--selected {
    color: $theme-primary;
    text-decoration: none;
    background: $theme-light;
  }
  @include hover-focus {
    color: $theme-primary;
    text-decoration: none;
    background: $theme-light;
  }
}

