
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
/* ==========================================================================
   #ICON
   ========================================================================== */

// Grey Icon + hover state
$icon-button-color: $theme-dark !default;
$icon-button-hover-color: $theme-dark-dk !default;
$icon-inline-margin: 3px;

// For multicolor icons only!
// For monochromatic ones use color props
$icon-styles: (
  'oc:paypal-small': (
    grayscale: (
      opacity: 0.4,
      fill: (
        1: #2c2c2c,
        2: #747474,
        3: #1b1b1b
      )
    )
  ),
  'oc:google-logo': (
    grayscale: (
      opacity: 0.5,
      fill: (
        1: #949494,
        2: #8d8d8d,
        3: #c5c5c5,
        4: #858585
      )
    )
  ),
  'oc:card': (
    grayscale: (
      fill: (
        1: #e3e3e3,
        2: #bbbcbc,
        3: #f4f4f4
      )
    )
  ),

  'oc:apple-pay': (
    grayscale: (
      opacity: 0.5
    )
  )
);

.icon {
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  position: relative;
  stroke: currentColor;
  vertical-align: middle;
  background-color: transparent;
}

// add right side spacing for inline icons
.inline {
  margin-right: $icon-inline-margin;
}

.button {
  @include button-reset;
}

// SVG Styles
.svg {
  display: block;

  @at-root {
    .stroke-none & { stroke: none; }
    .stroke-inherit & { stroke: inherit; }

    .w-auto & { width: auto; }
    .h-auto & { height: auto; }

    // Icon sizes
    @each $size, $value in $asset-size {

      // Horizontal size
      .x-#{$size} & {
        width: rem($value);
      }

      // Vertical Size
      .y-#{$size} & {
        height: rem($value);
      }

      // square size
      .size-#{$size} & {
        @include size(rem($value));
      }
    }

    // Colors
    @each $name, $color in $theme-clrs {
      .#{$name} & {
        stroke: $color;
      }

      .fill-#{$name} & {
        fill: $color;
      }
    }

    @each $icon, $iconStyles in $icon-styles {
      &[data-icon-name="#{$icon}"] {
        @each $iconType, $opts in $iconStyles {
          &.#{$iconType} {
            :global {
              @include icon-style($opts);
            }
          }
        }
      }
    }
  }
}


// [TODO] Add Round Icon variants
// [TODO] Add Custom Icons

// // Greyscale icons
// .#{$icon-class}-grayscale {
//   $icon-wrapper: &;

//   .svg-group--animated {
//     transition: $gl-transition-base;
//   }

//   // Paypal Icon
//   // Attention! : A paypal icon must have two groups for 'svg-paypal--pay' and 'svg-paypal--pal
//   //              with ids 'pay' and 'pal' correspondingly

//   @include svg-group-fill(paypal, pay, $theme-dark);
//   @include svg-group-fill(paypal, pal, $theme-dark);

//   &:hover {
//     @include svg-group-fill(paypal, pay, $clr-paypal-pay);
//     @include svg-group-fill(paypal, pal, $clr-paypal-pal);
//   }
// }

// // grey icon with hover
// .#{$icon-class}--button {
//   * {
//     transition: all 0.1s;
//   }

//   .#{$icon-class}__svg {
//     stroke: $icon-button-color;
//   }

//   // hover and active states
//   @at-root {
//     &:hover,
//     &.is-active,
//     .is-active > & {
//       .#{$icon-class}__svg {
//         stroke: $icon-button-hover-color;
//       }
//     }
//   }
// }
