
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.container {
  padding: $gl-spacer-s 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}


// @each $size, $value in $asset-size {

//   // Horizontal size
//   .x-#{$size} & {
//     width: rem($value);
//   }

//   // Vertical Size
//   .y-#{$size} & {
//     height: rem($value);
//   }

//   // square size
//   .size-#{$size} & {
//     @include size(rem($value));
//   }
// }

.spinner {
  @include size($gl-asset-size-xs);
  animation: rotating 1s linear infinite;
}

.spinner__oh {
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.spinner__inner {
  @include size($gl-asset-size-xs);
  box-sizing: border-box;
  display:block;
  border: 2px solid $theme-dark-dkr;
  border-radius: 50%;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
