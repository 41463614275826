
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$border-color: $theme-light-dk;

.container {
  display: block;
  width: 296px;
  font-weight: normal;
}

.content {
  font-size: $gl-font-size-xs;
  overflow-y: auto;
  box-shadow: none;

  min-height: 2em;
  max-height: 279px;
}

.footer {
  border-top: 1px solid $border-color;
  text-align: left;
  padding: $gl-spacer-xs $gl-spacer-m;
  font-size: $gl-font-size-s;

  &__btn {
    @include button-reset();

    color: $theme-primary;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.item {
  padding: $gl-spacer-xs $gl-spacer-m;

  & + & {
    border-top: 1px solid $border-color;
  }

  &-title {
    font-weight: 500;
    color: $theme-dark-dkr;
    font-size: $gl-font-size-base;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-subtitle {
    font-weight: normal;
    color: $theme-dark;
    font-size: $gl-font-size-s;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.empty-state {
  // padding: $gl-spacer-s $gl-spacer-xl;
  padding: 24px 42px;
  line-height: $gl-line-height-sm;

  text-align: center;
  display: block;
  font-size: $gl-font-size-base;
  font-weight: normal;
  color: $theme-dark;
}

.add-course {
  @include button-reset;

  @include plain-hover-focus {
    color: $theme-primary;
    font-size: $gl-font-size-base;
    font-weight: normal;
  }
  padding: 0;
  margin: 0;

  display: inline;
}
