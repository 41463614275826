// SVG manipulations mixins


// Generate a class and a fill rule for the svg group

@mixin svg-group-fill($baseName, $groupName, $fillColor) {
  .svg-#{$baseName}--#{$groupName} {
    fill: $fillColor;
  }
}



@mixin icon-style($opts) {
  & {
    @if (map-has-key($opts, opacity)) {
      opacity: map-get($opts, opacity);
    }

    @if (map-has-key($opts, fill)) {
      $fill: map-get($opts, fill);

      @each $name, $color in $fill {

        .svg-group__item--#{'' + $name} {
          fill:$color;
        }

      }
    }
  }
}
